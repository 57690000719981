<template>
  <!-- add contract dialog -->
  <Dialog header="Регистрация на мероприятие/собрание" :visible="modalEventRegistration.displayEventRegistrationModal" :closable="false" :modal="true"
          :style="{width: '30vw'}">
    <div class="my-3">
      <h5>{{ modalEventRegistration.events_name }}</h5>
    </div>
    <template #footer>
      <Button label="Закрыть" icon="pi pi-times" @click="registerOrNot(0)" class="p-button-text"/>
      <Button label="Сохранить" icon="pi pi-plus" @click="registerOrNot(1)" autofocus/>
    </template>
  </Dialog>
</template>
<script>
export default {
  name: "EventRegistrationModal",
  props: {
    modalEventRegistration: {
      type: Object
    },
  },
  methods: {
    registerOrNot(is_close){
      this.$emit("registerToEvent", is_close)
    }
  }
}
</script>

<style scoped>

</style>