<template>
  <div class="container text-center">
    <h1>Список мероприятии</h1>
    <h5>выберите мероприятие которая вас интересует</h5>
    <div class="row mt-3">
      <div class="col-md-4 col-sm-12 col-xs-12 mb-2" v-for="(event, index) in studentEvents" :key="index">
        <div class="card" style="width: 100%">
          <div class="card-body">
            <h4 class="card-title">{{ event.events_name }}</h4>
            <p class="card-text"><span class="competence me-1 p-1"
                                       v-for="(competence, competence_index) in event.competencies"
                                       :key="competence_index">{{ competence }}</span></p>
            <h6 class="card-subtitle mb-2 text-muted">{{ event.start_date }}</h6>
            <button type="button" class="btn btn-primary" :disabled="event.is_registered === 1"
                    @click="openModal(index)">Зарегистрироваться
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EventRegistrationModal :modalEventRegistration="modalEventRegistration" @registerToEvent="registerToEvent"/>
</template>

<script>
import {mapActions, mapState} from "vuex";
import EventRegistrationModal from "@/components/events/EventRegistrationModal.vue";

export default {
  name: "StudentEventRegistration",
  components: {
    EventRegistrationModal,
  },
  data() {
    return {
      modalEventRegistration: {
        displayEventRegistrationModal: false,
        events_id: null,
        events_name: ""
      }
    }
  },
  computed: {
    ...mapState('studentEventRegistration', ['studentEvents']),
  },
  methods: {
    ...mapActions('studentEventRegistration', ['GET_STUDENT_EVENTS', 'REGISTER_TO_EVENT']),
    openModal(index) {
      this.modalEventRegistration = {
        displayEventRegistrationModal: true,
        events_id: this.studentEvents[index].events_id,
        events_name: this.studentEvents[index].events_name,
      }
    },
    registerToEvent(is_close) {
      if (is_close !== 0) {
        if (this.REGISTER_TO_EVENT(
            this.modalEventRegistration.events_id)) {
          this.$message({title: 'Вы зарегистрировались на: ' + this.modalEventRegistration.events_name})
          this.GET_STUDENT_EVENTS()
        }
      }
      this.clearModalEventRegistration()
    },
    clearModalEventRegistration() {
      this.modalEventRegistration = {
        displayEventRegistrationModal: false,
        events_id: null,
        events_name: ""
      }
    }
  },
  async mounted() {
    await this.GET_STUDENT_EVENTS()
  }
}
</script>

<style scoped>
.competence {
  background-color: #B7D4FF;
  border-radius: 5px;
}
</style>